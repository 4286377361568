<template>
  <WsMain>
    <WsCrud
      :modelName="$store.state.stone_model[modelName].modelName"
      :label="$store.state.stone_model[modelName].label"
      :fields="$store.state.stone_model[modelName].fields"
      :liveSearching="true"
      :showFields="showFields"
      :infiniteScroll="true"
      :creatable="false"
      :updatable="false"
      :deletable="false"
      :showExpand="false"
      :expandable="false"
    ></WsCrud>
  </WsMain>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$store.state.stone_model[this.modelName].label,
    };
  },

  data: () => ({
    modelName: "cms_log",
    showFields: [
      "admin",
      "payload.action",
      "created_at",
      "payload.target",
      "payload.target_id",
      "payload.ip",
      "payload.remark",
    ],
  }),
};
</script>